<template>
    <div class="card" style="margin-top: 0">
        <div class="tx-history-wrap desktop-table">
            <div class="card-title">
                <ui-link class="card-title__icon" v-bind:to="{ name: 'blocks' }">
                    <icon-back fill="currentColor"/>
                </ui-link>

                <template v-if="workchain === -1">
                    {{$t('blocks.last_masterchain_blocks_title')}}
                </template>

                <template v-else>
                    {{$t('blocks.last_workchain_blocks_title')}}
                </template>
            </div>

            <table class="ui-table">
                <thead>
                    <tr>
                        <th v-text="$t('blocks.block')"/>
                        <th v-text="$t('blocks.transactions')"/>
                        <th v-if="!isMobile" v-text="$t('tx.hash')"/>
                        <th v-text="$t('common.time')"/>
                    </tr>
                </thead>

                <tbody v-if="blocks.length > 0">
                    <tr class="new-row-animation"
                        v-for="block in blocks"
                        v-bind:key="`${block.workchain}:${block.seqno}`">
                        <td>
                            <ui-link v-bind:to="{ name: 'block', params: { ...block }}">
                                {{block.seqno}}
                            </ui-link>
                        </td>
                        <td>
                            <span style="padding-left: 36px;">
                                {{ block.tx_count }}
                            </span>
                        </td>
                        <td v-if="!isMobile" v-text="block.root_hash_hex"/>
                        <td>
                            <ui-datetime hide-date v-bind:timestamp="block.gen_utime"/>
                        </td>
                    </tr>
                </tbody>

                <tbody v-else>
                    <tr v-for="i of 40" v-bind:key="`page_blocks_workchain_blocks_skeleton_${i}`">
                        <td>
                            <span class="skeleton">123456789</span>
                        </td>
                        <td>
                            <span class="skeleton">9999</span>
                        </td>
                        <td v-if="!isMobile">
                            <span class="skeleton">AbAbAbAbAbAbAbAbAbAbAAbAbAbAbAbA</span>
                        </td>
                        <td>
                            <span class="skeleton">00:00:00 AM</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <ui-mugen-scroll show-button
                v-bind:handler="loadMore"
                v-bind:shouldHandle="shouldHandleScroll"
                v-bind:isLoading="isLoading"/>
        </div>
    </div>
</template>

<script>
import { getPreviousBlocks } from '~/api';
import IconBack from '@primer/octicons/build/svg/arrow-left-24.svg?vue';

export default {
    timerHandle: undefined,

    props: {
        workchain: [Number, String],
    },

    data() {
        return {
            blocks: [],
            limit: 64,
            isLoading: false,
            // Whether to keep the size of the block table when new blocks appear.
            // Default is true, so that the table won't grow indefinetely if user opens the page and leaves.
            // Should be set to false if user intentionally requests more blocks (scrolls down or requests new page).
            truncateBlockList: true,
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading;
        },
    },

    async created() {
        this.blocks = await getPreviousBlocks({
            wc: this.workchain,
            limit: this.limit,
        });

        console.log(this.blocks)

        this.$options.timerHandle = setInterval(() => this.loadLatestBlock(), 2800);
    },

    beforeDestroy() {
        clearInterval(this.$options.timerHandle);
    },

    methods: {
        async loadLatestBlock() {
            const newBlocks = await getPreviousBlocks({
                wc: this.workchain,
                afterLt: this.blocks.at(-1).gen_utime,
            });

            this.blocks.unshift(...newBlocks);

            if (this.truncateBlockList) {
                this.blocks.splice(this.limit);
            }
        },

        async loadMore() {
            this.truncateBlockList = false;
            this.isLoading = true;

            const newBlocks = await getPreviousBlocks({
                wc: this.workchain,
                limit: this.limit,
                endUtime: this.blocks.at(-1).gen_utime - 1,
            });

            this.blocks = this.blocks.concat(newBlocks);
            this.isLoading = false;

            this.offset += this.limit;
        },
    },

    components: {
        IconBack,
    },
};
</script>

<style lang="scss">
.last-blocks-table {
    overflow: hidden;

    td, th {
        text-align: center;
    }

    tr:last-child > td {
        border-bottom: 1px solid var(--card-border-color);
    }
}
</style>
