<template>
    <section>
        <section v-if="isError">
            <div class="alert" v-text="$t('error.invalid_tx')" />
        </section>

        <div v-show="!isError" class="card">
            <div class="card-title">
                <icon-tx-type-ordinary class="card-title__icon" /> {{ $t('tx.title') }}
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('tx.account')" />
                <div class="card-row__value">
                    <div v-if="address" style="display: flex;">
                        <ui-address hide-name v-bind:address="address" />
                        <ui-copy-button v-bind:copy="address" v-bind:successMessage="$t('address.info.copy_success')" />
                    </div>
                    <span v-else class="skeleton">EQDCHV6...LoH</span>
                </div>
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('tx.status')" />
                <div class="card-row__value">
                    <span class="tx-status" v-bind:class="{
                        'tx-status--success': isSuccess,
                        'tx-status--failed': !isSuccess,
                    }">
                        <span v-if="isSuccess === undefined" class="skeleton">Unknown</span>
                        <template v-else-if="isSuccess">
                            <icon-success class="tx-status__icon" />
                            {{ $t('tx.status_success') }}
                        </template>
                        <template v-else>
                            <icon-failed class="tx-status__icon" />
                            {{ $t('tx.status_failed') }}
                        </template>
                    </span>
                </div>
            </div>

            <div class="card-row" v-if="isSuccess === false">
                <div class="card-row__name" v-text="$t('tx.exit_codes')" />
                <div class="card-row__value">
                    <div class="tx-flow-schematics">
                        <div class="tx-flow-schematics-step">
                            <div class="tx-flow-schematics-step__inner">
                                <header class="tx-flow-schematics-step__phase muted" v-text="$t('tx.compute_phase')" />
                                <div class="tx-flow-schematics-step__result">
                                    <template v-if="computeExitCode === null">
                                        {{ $t('tx.phase_aborted') }}
                                    </template>
                                    <template v-else>
                                        {{ computeExitCode }}
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="tx-flow-schematics-step">
                            <div class="tx-flow-schematics-step__inner">
                                <header class="tx-flow-schematics-step__phase muted" v-text="$t('tx.action_phase')" />
                                <div class="tx-flow-schematics-step__result">
                                    <template v-if="actionResultCode === null">
                                        {{ $t('tx.phase_aborted') }}
                                    </template>
                                    <template v-else>
                                        {{ actionResultCode }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('tx.timestamp')" />
                <div class="card-row__value">
                    <template v-if="timestamp">
                        <ui-datetime v-bind:timestamp="timestamp" />
                        &mdash;
                        <ui-timeago v-bind:timestamp="timestamp" />
                    </template>
                    <span v-else class="skeleton">00/00/2000 10:00</span>
                </div>
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('tx.lt')" />
                <div class="card-row__value">
                    <template v-if="lt">
                        <ui-copy-button v-bind:copy="lt" v-bind:successMessage="$t('tx.lt_copy_success')">
                            {{ lt }}
                        </ui-copy-button>
                    </template>
                    <span v-else class="skeleton">0000000000000</span>
                </div>
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('tx.hash')" />
                <div class="card-row__value card-row__value--flex-row">
                    <div class="card-row">
                        <div class="card-row__name">base64</div>
                        <div class="card-row__value">
                            <span v-if="isLoading" class="skeleton">0000000000000</span>
                            <ui-copy-button v-else v-bind:copy="hashBase64"
                                v-bind:successMessage="$t('tx.hash_copy_success')">
                                {{ hashBase64 }}
                            </ui-copy-button>
                        </div>
                    </div>
                    <div class="card-row">
                        <div class="card-row__name">hex</div>
                        <div class="card-row__value">
                            <span v-if="isLoading" class="skeleton">0000000000000</span>
                            <ui-copy-button v-else style="text-transform: lowercase;" v-bind:copy="hashHex"
                                v-bind:successMessage="$t('tx.hash_copy_success')">
                                {{ hashHex }}
                            </ui-copy-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('tx.fee')" />
                <div class="card-row__value card-row__value--flex-row">
                    <div class="card-row">
                        <div class="card-row__name">total</div>
                        <div class="card-row__value">
                            <span v-if="isLoading" class="skeleton">0.1 TON</span>
                            <span v-else>{{ $fee(fee) }} TON</span>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name">storage</div>
                        <div class="card-row__value">
                            <span v-if="isLoading" class="skeleton">0.1 TON</span>
                            <span v-else>{{ $fee(storageFee) }} TON</span>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name">other</div>
                        <div class="card-row__value">
                            <span v-if="isLoading" class="skeleton">0.1 TON</span>
                            <span v-else>{{ $fee(otherFee) }} TON</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-row" style="border-bottom: none;">
                <div class="card-row__name" v-text="$t('tx.msgs')" />
                <div class="card-row__value">
                    <span v-if="isLoading" class="skeleton">1 input, 1 output</span>
                    <span v-else-if="!inMsg && !outMsgs.length" v-text="$t('tx.msgs_empty')" />
                    <span v-else v-text="$t('tx.msgs_count', [1, outMsgs.length])" />
                </div>
            </div>

            <div class="tx-page-messages">
                <div v-if="inMsg" class="tx-page-msg">
                    <div class="card-row__name">
                        <span class="tx-table__badge tx-table__badge--in">IN</span>
                    </div>
                    <tx-msg class="tx-page-msg-details"
                        v-bind:source="addressBook[inMsg.source]?.user_friendly || null"
                        v-bind:destination="addressBook[inMsg.destination]?.user_friendly || null"
                        v-bind:value="inMsg.value"
                        v-bind:fwdFee="inMsg.fwd_fee"
                        v-bind:ihrFee="inMsg.ihr_fee"
                        v-bind:createdLt="inMsg.created_lt"
                        v-bind:hash="inMsg.hash"
                        v-bind:comment="inMsg.message_content?.decoded?.comment"
                        v-bind:op="inMsg.opcode" />
                </div>

                <div class="tx-page-msg" v-for="msg in outMsgs" v-bind:key="msg.hash">
                    <div class="card-row__name">
                        <span v-if="!msg.destination" class="tx-table__badge tx-table__badge--service"
                            v-text="$t('address.tx_table.log')" />

                        <span v-else class="tx-table__badge tx-table__badge--out" v-text="$t('address.tx_table.output')" />
                    </div>

                    <tx-msg class="tx-page-msg-details"
                        v-bind:source="addressBook[msg.source]?.user_friendly || null"
                        v-bind:destination="addressBook[msg.destination]?.user_friendly || null"
                        v-bind:value="msg.value"
                        v-bind:fwdFee="msg.fwd_fee"
                        v-bind:ihrFee="msg.ihr_fee"
                        v-bind:createdLt="msg.created_lt"
                        v-bind:hash="msg.hash"
                        v-bind:comment="msg.message_content?.decoded?.comment"
                        v-bind:op="msg.opcode" />
                </div>
            </div>

            <div style="display: none">
                <a ref="devExplorerLink" target="_blank">View in Toncoin Explorer</a>
            </div>
        </div>
    </section>
</template>

<script>
import IconTxTypeOrdinary from '@primer/octicons/build/svg/git-commit-24.svg?inline';
import IconSuccess from '@primer/octicons/build/svg/check-circle-16.svg?inline';
import IconFailed from '@primer/octicons/build/svg/x-circle-16.svg?inline';
import { goToDevExplorerMixin } from '~/mixins';
import { hexToBase64, toBase64Web, base64ToHex } from '~/utils.js';
import { getTransactionByHashOrInMessageHash, getTransactionByInMsgHash } from '~/api';
import TxMsg from './TxMsg.vue';

export default {
    props: {
        hash: String,
    },

    data() {
        return {
            address: undefined,
            addressBook: undefined,
            type: undefined,
            isSuccess: undefined,
            exitCode: undefined,
            computeExitCode: undefined,
            actionResultCode: undefined,
            computeVmSteps: undefined,
            fee: undefined,
            storageFee: undefined,
            otherFee: undefined,
            timestamp: undefined,
            isLoading: true,
            isError: false,
            hashBase64: undefined,
            hashHex: undefined,
            lt: undefined,
            outMsgs: [],
            inMsg: undefined,
        };
    },

    computed: {
        isGettingByMsgHash() {
            return this.$route.name === 'tx_by_msg_hash';
        },

        devExplorerUrl() {
            return `/transaction?account=${this.address}&lt=${this.lt}&hash=${this.hashHex}`;
        },
    },

    created() {
        // redirect hex-formatted hash to base64-formatted:
        if (this.$route.params.hash.length === 64) {
            this.$router.replace(this.$localizeRoute({
                name: this.$route.name,
                params: {
                    hash: toBase64Web(hexToBase64(this.$route.params.hash)),
                },
            }));
            return;
        }

        this.loadData();
    },

    watch: {
        $route: 'loadData',
    },

    methods: {
        async loadData() {
            this.isLoading = true;
            this.isError = false;

            // both hash and in_msg_hash pages are served with this component:
            const apiMethod = this.isGettingByMsgHash
                ? getTransactionByInMsgHash
                : getTransactionByHashOrInMessageHash;

            try {
                const txRequest = await apiMethod(this.hash);
                const tx = txRequest.transactions[0];
                this.addressBook = txRequest.address_book;
                this.address = this.addressBook[txRequest.transactions[0].account].user_friendly;

                // in_msg hash matches the requested hash, meaning that we're searching
                // for in_msg_hash on tx page, need to replace URL:

                if (tx?.in_msg?.hash && toBase64Web(tx.in_msg.hash) === toBase64Web(this.hash)) {
                    this.$router.replace(this.$localizeRoute({
                        name: 'tx_by_msg_hash',
                        params: { hash: toBase64Web(this.hash) },
                    }));
                }

                // both phases are skipped if we're activating the new wallet, we should consider this tx successful:
                const newWalletTxSuccess = tx.description?.action?.result_code === null && tx.description?.compute_ph?.exit_code === null;
                const executionSuccess = tx.description.action?.action_result_code !== null && parseInt(tx.description?.action?.result_code, 10) <= 1;

                this.isSuccess = newWalletTxSuccess || executionSuccess;
                this.exitCode = this.isSuccess
                    ? tx.action_result_code
                    : tx.compute_exit_code;

                this.computeExitCode = tx.description?.compute_ph?.exit_code || null;
                this.actionResultCode = tx.description?.action?.result_code || null;
                this.computeVmSteps = tx.description?.compute_ph?.vm_steps;

                this.fee = tx.total_fees;
                this.storageFee = tx.description?.storage_ph?.storage_fees_collected || 0;
                this.otherFee = tx.total_fees - this.storageFee || 0;
                this.timestamp = parseInt(tx.now + '000', 10);
                this.outMsgs = tx.out_msgs.map(Object.freeze);
                this.inMsg = tx.in_msg
                    ? Object.freeze(tx.in_msg)
                    : undefined;

                this.hashHex = base64ToHex(tx.hash);
                this.hashBase64 = tx.hash;
                this.lt = tx.lt;
            } catch (e) {
                this.isError = true;
                console.error(e);
            }

            this.isLoading = false;
        },
    },

    metaInfo() {
        return {
            title: this.$t('tx.meta.title', { hash: this.hash }),
            meta: [{
                property: 'robots',
                content: 'noindex',
            }],
        };
    },

    components: {
        TxMsg, IconTxTypeOrdinary, IconSuccess, IconFailed,
    },

    mixins: [goToDevExplorerMixin],
};
</script>

<style lang="scss">
.tx-status {
    display: flex;
    align-items: center;
    font-weight: 500;

    &--failed {
        color: var(--page-tx-status-error-color);
    }

    &--success {
        color: var(--page-tx-status-success-color);
    }

    &__icon {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        fill: currentColor;
    }
}

.tx-flow-schematics {
    display: flex;
    align-items: center;
    border: 1px solid var(--page-tx-flow-diagram-border-color);
    overflow: hidden;
    border-radius: 8px;
}

.tx-flow-schematics-step {
    display: flex;
    align-items: center;

    &__inner {
        padding: 4px 10px;
        display: flex;
        flex-direction: column;
    }

    &__phase {
        font-size: 12px;
    }

    &::after {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        border-color: var(--page-tx-flow-diagram-border-color);
        border-width: 0 1px 1px 0;
        border-style: solid;
        transform: rotate(-45deg);
        margin: 0 8px 0 -28px;
        user-select: none;
        pointer-events: none;
    }

    &:last-child::after {
        display: none;
    }
}
</style>
